<template>
  <div class="case-study" id="case-led">
    <b-container>
      <b-row class="case-header" id="ur-hero">
        <b-col cols='3' offset="1">

          <p class="eyebrow">
            United Rentals
          </p>
          <h3>Mobile management of large equipment</h3>

        </b-col>
      </b-row>
      <b-row class="case-intro">
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="4">
          <p class="lead">
            I conducted research and designed a mobile app, allowing the management of large scale equipment rental from a job site.
          </p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            Research
          </p>
        </b-col>
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="0" class="bb-case">
          <p>
            Through competitive and comparative audits, stakeholders discussions as well as interviews with representative end users, I was able to identify a collection of product requirements and feature categories to serve as the basis for an improved mobile experience.
          </p>
          <p>
            Through this process, I wrote scripts, moderated user discussions, and synthesized outcomes.
          </p>
          <h4>Interviews</h4>
          <p>
            The interviews consisted of a range of end users consisting of equipment operators and site foremen, project managers and superintendents, as well as internal United employees. The interviews were conducted over 1 hour sessions and were used to gain a formative understanding of the equipment rental industry.
          </p>
          <h4>Key Insights</h4>
          <ul>
            <li>For users on the ground everything is centered around a job site, while for office workers everything is contract focused, which may span job sites </li>
            <li>Customers on the ground rely on others for their transactions</li>
            <li>Multiple channels complicate customer communication</li>
            <li>Tracking of specific equipment is difficult</li>
          </ul>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            Mapping the <br/>User Journey
          </p>
        </b-col>
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="0">
          <p>
            The formative interviews, product owners, and subject matter experts helped us form a user journey of the current customer. This journey showed the multiple tools and people a user would interact with over the course of renting a piece of equipment, which many stakeholders where unaware of, since they were usually isolated to their individual segment.
          </p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12">
          <img src="@/img/ur/ur-journey.png"/>
          <p class="caption">Mapping the customer journey across the rental experience.</p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            Planning &amp; Iteration
          </p>
        </b-col>
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="0" >
          <p>
            Before visual design began it was important to understand the general architecture of the application. Forming an application map, we were able to identify key flows, repeatable actions, and form a general architecture. While this map was revised throughout the design process, establishing an early draft allowed us to work with the client on identifying the problems and solutions and quickly adjust.
          </p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12">
          <img src="@/img/ur/ur-ux-arch.png"/>
          <p class="caption">Mapping out the application architecture for the eventual mobile app.</p>
        </b-col>
      </b-row>
      <!--
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="4" class="bb-case">
          <h4>Prototyping</h4>
          <p>
            I also built early low fidelity prototypes, showing flows through the experience. These were helpful to show early, guiding the design at a low fidelity and allowing iteration often before development began.
          </p>
        </b-col>
      </b-row>
    -->
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            Final Designs
          </p>
        </b-col>
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="0" >
          <p>
            Technicians can also pivot their view from Patient focused to viewing their storage based on their storage tank. This allows the user to manage their storage solutions holistically.
          </p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12">
          <img src="@/img/ur/ur-finals.jpg"/>
          <p class="caption">A collection of the final screens created for the application.</p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="4">
          <h4>Categories</h4>
          <p>
            Categories allow users to organize their fleet and view equipment they need to see. This could be displaying equipment that is overdue, nearby, or the deliveries expected that day.
          </p>
          <p>
            This helps multiple target users, such as job site foremen or a central office based procurement manager, to use the same application.
          </p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col  cols="12" md="10" lg="6" offset-md="1" offset-lg="4">
          <img src="@/img/ur/ur-categories.png"/>
          <p class="caption">Editable categories allow for users to define their experience.</p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="4">
          <h4>Equipment Details</h4>
          <p>
            Using the equipment details page, a user can view information about the equipment they have on rent. Such as the location, if it is GPS enabled, its activity, and an overview of the related rental contract.
          </p>
          <p>
            Users can also take action on their equipment from the detail page, extending or ending their rental contracts. Allowing users to easily manage their fleet on the ground.
          </p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col  cols="12" md="10" lg="6" offset-md="1" offset-lg="4">
          <img src="@/img/ur/ur-details.png"/>
          <p class="caption">Equipment details pages allowed users to see insights and information about their equipement.</p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="4">
          <h4>Location Based View</h4>
          <p>
            Finding specific equipment is one of the biggest issues on a job site, if you have multiple forklifts, which one are you currently looking at?
          </p>
          <p>
            Using a map based view and location based sorting, users can easily see if the forklift they are standing next to is the same one they are viewing in the app.
          </p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col  cols="12" md="10" lg="6" offset-md="1" offset-lg="4">
          <img src="@/img/ur/ur-location.png"/>
        </b-col>
      </b-row>
      <b-row class="related-list">
        <b-col cols="12">
          <div class="section-title">
            Other Projects
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="4" v-for="project in related">
          <ProjectCard
            :img="project.img"
            :tags="project.tags"
            :title="project.title"
            :description="project.description"
            :logo="project.logo"
            :route="project.route"
          >
          </ProjectCard>
        </b-col>
      </b-row>



    </b-container>


  </div>
</template>

<script>
import ProjectCard from '@/components/ProjectCard.vue'
import { projects } from '@/data/projects.js'

export default {
  name: 'UR',
  data() {
    return {
      projects: projects
    }
  },
  components: {
    ProjectCard
  },
  computed: {
    related: function () {
      var related = [
        this.projects[3],
        this.projects[4],
        this.projects[5]
      ]
      return related
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
#ur-hero
  background-position: right 5% bottom 0%
  background-size: contain
  background-repeat: no-repeat
  background-image: url('~@/img/ur/ur-banner.png')


</style>

<template>
  <div class="case-study" id="case-led">
    <b-container>
      <b-row class="case-header" id="freeze-hero">
        <b-col cols='3' offset="1">

          <p class="eyebrow">
            Freeze
          </p>
          <h3>First of its kind web app for cryo storage</h3>

        </b-col>
      </b-row>
      <b-row class="case-intro">
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="4">
          <p class="lead">
            I designed interfaces for the storage of genetic material. Including a design system and its application across a product called Freeze.
          </p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            Problem
          </p>
        </b-col>
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="0" class="bb-case">
          <p>
            Genetic cryogenic centers allow a patient to store their genetic material. These labs use freezing tanks to keep the specimens for a patient’s future use.
          </p>
          <p>
            Often, centers use paper, pens, filing cabinets, and occasionally Excel, to manage their storage of the patient’s specimens. There is little standardization with each center using their home grown methods of record keeping.
          </p>
          <p>
            Freeze is a web application allowing these centers to centrally manage and bring structure to their data.
          </p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            A Digital Solution
          </p>
        </b-col>
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="0" >
          <p>
            A digital tool was created to solve the pain points observed. It enables lab technicians to oversee their lab and the patients and freezers being used.
          </p>
          <p>
            The tool has been rolled out to clinics and begun replacing the spreadsheets and pens which were previously being used.
          </p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12">
          <img src="@/img/freeze/freeze-overview.jpg"/>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="4" >
          <h4>Patient Management</h4>
          <p>
            Freeze allows lab technicians to efficiently store and retrieve a patient’s specimens. A tech can view all of a patient’s specimens, see where they are stored, and take action—such as adding a new one, removing one from storage, or donating it to another patient or clinic.
          </p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12" md="10" lg="8" offset-md="1" offset-lg="3">
          <img src="@/img/freeze/freeze-view-patient.jpg"/>
          <p class="caption">The patient view allows for users to see all their patient's and the associated specimens.</p>
          <img/>
        </b-col>
      </b-row>
      <b-row class="case-row mt-sm">
        <b-col cols="12" md="10" lg="8" offset-md="1" offset-lg="3">
          <img src="@/img/freeze/freeze-view-patient-detail.jpg" class="img-border"/>
          <p class="caption">Patient details allow for users to see granular details of each specimen, such as their location and notations.</p>
          <img/>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="4" >
          <h4>Specimen Management</h4>
          <p>
            Technicians can add specimens, they are stepped through the flow presenting fields which need to be specified in an organized process.
          </p>
          <p>
            The separation of steps allows subsequent steps to respond to previous answers.
          </p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col  cols="12" md="10" lg="5" offset-md="1" offset-lg="2">
          <img src="@/img/freeze/freeze-view-add-1.jpg"/>
          <p class="caption">Users are able to use the tool to add new specimen to their inventory.</p>
        </b-col>
        <b-col  cols="12" md="10" lg="5" offset-md="1" offset-lg="0">
          <img src="@/img/freeze/freeze-view-add-2.jpg"/>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="4" >
          <h4>Equipment Management</h4>
          <p>
            Technicians can also pivot their view from Patient focused to viewing their storage based on their storage tanks. This allows the user to manage their storage solutions holistically, to perform inventory based actions.
          </p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col  cols="12" md="10" lg="8" offset-md="1" offset-lg="3">
          <img src="@/img/freeze/freeze-view-tanks-overview.png" class="img-border"/>
          <p class="caption">Tank overviews, allows users to view total inventory.</p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            Design System
          </p>
        </b-col>
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="0" >
          <p>
            As a new product entering the market, I named Freeze, and created a design system, providing design direction and guidelines across the product.
          </p>
          <p>
            This system included:
          </p>
          <ul>
            <li>Design files, Sketch and later Figma</li>
            <li>A CSS library</li>
            <li>Example front end prototypes</li>
          </ul>
          <h4>Design Assets</h4>
          <p>
            Using Sketch, I built symbols which scaled across the system. The symbols were used to ensure consistent design assets where produced when working with the development team. The symbols were later converted to Figma components as well.
          </p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12">
          <img src="@/img/freeze/freeze-dls.jpg"/>
          <p class="caption">An overview of some of the elements created for the design system.</p>
        </b-col>

      </b-row>
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="4" >
          <h4>Functional Design</h4>
          <p>
            I then translated the design assets into a CSS library which was implemented into the front end.  This allowing the development team to quickly ramp up and implement designs accurately, allowing iteration on the functionality and flow,  and not getting hung up on the styling.
          </p>
        </b-col>
      </b-row>
      <b-row class="case-row">

        <b-col cols="12">
          <img src="@/img/freeze/freeze-dls-coded.jpg" class="img-border"/>
          <p class="caption">I then translated the design elements to front end components.</p>
        </b-col>

      </b-row>
      <b-row class="related-list">
        <b-col cols="12">
          <div class="section-title">
            Other Projects
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="4" v-for="project in related">
          <ProjectCard
            :img="project.img"
            :tags="project.tags"
            :title="project.title"
            :description="project.description"
            :logo="project.logo"
            :route="project.route"
          >
          </ProjectCard>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import ProjectCard from '@/components/ProjectCard.vue'
import { projects } from '@/data/projects.js'

export default {
  name: 'Freeze',
  data() {
    return {
      projects: projects
    }
  },
  components: {
    ProjectCard
  },
  computed: {
    related: function () {
      var related = [
        this.projects[1],
        this.projects[2],
        this.projects[3]
      ]
      return related
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
#freeze-hero
  background-position: right 5% bottom 0%
  background-size: cover
  background-repeat: no-repeat
  background-image: url('~@/img/freeze/freeze-banner.png')





</style>

<template>
  <div class="case-study" id="case-led">
    <b-container>
      <b-row class="case-header" id="led-hero">
        <b-col cols='3' offset="1">

          <p class="eyebrow">
            Magic Leap
          </p>
          <h3>LED indicators as a hardware interface</h3>

        </b-col>
      </b-row>
      <b-row class="case-intro">
        <b-col xs="12" md="8" lg="6" offset-md="2" offset-lg="4">
          <p class="lead">
            I designed LED patterns to indicate interaction behaviors of Magic Leap hardware.
          </p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            About
          </p>
        </b-col>
        <b-col xs="12" md="8" lg="6" offset-md="2" offset-lg="0" >
          <p>
            Magic Leap is a headmounted AR device. LED indicators on the device allow users to understand the device’s current state and processes. The LED indicators serve to outwardly communicate these states without needing to wear the device. These communications were important to specific user segments who may not actively wear the device during use, such as developers, IT admins, and exhibitors.
          </p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col xs="12" class="video-container">
          <iframe src="https://www.youtube-nocookie.com/embed/anFdycrDkr0?controls=0&showinfo=0&modestbranding=1&autoplay=1&loop=1&playlist=anFdycrDkr0&rel=0&mute=1 " frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="video"></iframe>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            Color
          </p>
        </b-col>
        <b-col xs="12" md="8" lg="6" offset-md="2" offset-lg="0" >
          <p>
            Specific event categories were given defined colors, allowing users to understand the type of event the device is communicating to them. Colors were iterated on through user feedback and industry standards, before arriving on the final version.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="10" lg="8" offset-md="1" offset-lg="3">
          <div class="color-table">
            <b-row class="color-table-header">
              <b-col cols="2">
                <p>Swatch</p>
              </b-col>
              <b-col cols="10">
                <container-fluid>
                  <b-row>
                    <b-col cols="12" md="3"><p>Color</p></b-col>
                    <b-col cols="12" md="7" class="md-hidden"><p>Description</p></b-col>
                  </b-row>
                </container-fluid>
              </b-col>

            </b-row>
            <b-row class="color-table-row">
              <b-col cols="2">
                <div class="color-swatch" id="swatch-white"></div>
              </b-col>
              <b-col cols="10">
                <container-fluid>
                  <b-row>
                    <b-col cols="12" md="3" class="led-color-label">White</b-col>
                    <b-col cols="12" md="7">Used for processing events. The device is actively performing actions the user initiated, such as startup or an update.</b-col>
                  </b-row>
                </container-fluid>
              </b-col>
            </b-row>
            <b-row class="color-table-row">
              <b-col cols="2">
                <div class="color-swatch" id="swatch-purple"></div>
              </b-col>
              <b-col cols="10">
                <container-fluid>
                  <b-row>
                    <b-col cols="12" md="3" class="led-color-label">Purple</b-col>
                    <b-col cols="12" md="7">Used to demonstrate ‘ready’ status, letting the user know they can use the device. </b-col>
                  </b-row>
                </container-fluid>
              </b-col>
            </b-row>
            <b-row class="color-table-row">
              <b-col cols="2">
                <div class="color-swatch" id="swatch-blue"></div>
              </b-col>
              <b-col cols="10">
                <container-fluid>
                  <b-row>
                    <b-col cols="12" md="3" class="led-color-label">Blue</b-col>
                    <b-col cols="12" md="7">For connectivity events, such as pairing wireless peripherals to the device. </b-col>
                  </b-row>
                </container-fluid>
              </b-col>
            </b-row>

            <b-row class="color-table-row">
              <b-col cols="2">
                <div class="color-swatch" id="swatch-red"></div>
              </b-col>
              <b-col cols="10">
                <container-fluid>
                  <b-row>
                    <b-col cols="12" md="3" class="led-color-label">Red</b-col>
                    <b-col cols="12" md="7">For error or warnings that need to be communicated, such as an incompatible charger or thermal events. </b-col>
                  </b-row>
                </container-fluid>
              </b-col>
            </b-row>

            <b-row class="color-table-row">
              <b-col cols="2">
                <div class="color-swatch" id="swatch-orange"></div>
              </b-col>
              <b-col cols="10">
                <container-fluid>
                  <b-row>
                    <b-col cols="12" md="3" class="led-color-label">Orange</b-col>
                    <b-col cols="12" md="7">Used when the battery is low and needs to be charged.</b-col>
                  </b-row>
                </container-fluid>
              </b-col>
            </b-row>

            <b-row class="color-table-row">
              <b-col cols="2">
                <div class="color-swatch" id="swatch-yellow"></div>
              </b-col>
              <b-col cols="10">
                <container-fluid>
                  <b-row>
                    <b-col cols="12" md="3" class="led-color-label">Yellow</b-col>
                    <b-col cols="12" md="7">Used for a battery with mid-status.</b-col>
                  </b-row>
                </container-fluid>
              </b-col>
            </b-row>

            <b-row class="color-table-row">
              <b-col cols="2">
                <div class="color-swatch" id="swatch-green"></div>
              </b-col>
              <b-col cols="10">
                <container-fluid>
                  <b-row>
                    <b-col cols="12" md="3" class="led-color-label">Green</b-col>
                    <b-col cols="12" md="7">Full battery indication.</b-col>
                  </b-row>
                </container-fluid>
              </b-col>
            </b-row>

          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            Curves
          </p>
        </b-col>
        <b-col xs="12" md="8" lg="6" offset-md="2" offset-lg="0" >
          <p>
            In addition to color, specific timing and curves were also paired with events. More passive actions, and events of normal device behavior are calmer in their visualizations, using slower times, and smoother curves in their transitions.
          </p>
          <p>
            Errors and warning events, that are more likely to need to alert the user, utilize faster frequencies, and sharper curves in their transitions.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" md="5" lg="4" offset-md="1" offset-lg="3">
          <img src="@/img/led/slowCurve.gif"/>
          <p class="caption">An example of successful event curves.</p>
        </b-col>
        <b-col xs="12" md="5" lg="4">
          <img src="@/img/led/fastCurve.gif"/>
          <p class="caption">An example of an error curve.</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            Priority
          </p>
        </b-col>
        <b-col xs="12" md="8" lg="6" offset-md="2" offset-lg="0" >
          <p>
            Multiple events may occur simultaneously. It was important to communicate to the user the activity of highest importance through the LEDs as well as taking into account user behavior with the device.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12">
          <img src="@/img/led/LED-priority.png"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            Process
          </p>
        </b-col>
        <b-col xs="12" md="8" lg="6" offset-md="2" offset-lg="0" >
          <p>
            The creation of the patterns were a cross-org effort through multiple rounds of iteration. It first started by understanding which hardware events were necessary for an end user to be aware of. Then working with the Industrial Design team to make sure we had all the LEDs necessary to communicate the events.
          </p>
          <p>
            Afterwards, video prototypes were made demonstrating the patterns while users interacted with previous generation hardware. Once the interactions and patterns were honed in, I worked with hardware engineering teams to reproduce the patterns on early prototyped hardware, where we iterated the patterns until they were reproducable and conveyed the same meaning we established from talking with users.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" md="10" lg="8" offset-md="1" offset-lg="3">
          <img src="@/img/led/led-ae.jpg"/>
          <p class="caption">Keyframes from After Effects, during early iterations of the visualizations.</p>
        </b-col>
      </b-row>
      <b-row class="mt-sm">
        <b-col xs="12" md="10" lg="8" offset-md="1" offset-lg="3">
          <img src="@/img/led/led-grid.jpg"/>
          <p class="caption">Collection of patterns as the designs were iterated on.</p>
        </b-col>
      </b-row>
      <b-row class="mt-sm">
        <b-col cols="12" md="5" lg="4" offset-md="1" offset-lg="3">
          <img src="@/img/led/led-table.png"/>
          <p class="caption">Timing tables provided documentation for the hardware engineering teams.</p>
        </b-col>
        <b-col cols="12" md="5" lg="4">
          <video autoplay muted loop>
            <source src="@/img/led/led-hw.webm" type="video/webm">
          Your browser does not support the video tag.
          </video>
          <p class="caption">Prototyping on early hardware.</p>
        </b-col>
      </b-row>

      <b-row class="related-list">
        <b-col cols="12">
          <div class="section-title">
            Other Projects
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="4" v-for="project in related">
          <ProjectCard
            :img="project.img"
            :tags="project.tags"
            :title="project.title"
            :description="project.description"
            :logo="project.logo"
            :route="project.route"
          >
          </ProjectCard>
        </b-col>
      </b-row>

    </b-container>

  </div>
</template>

<script>
import ProjectCard from '@/components/ProjectCard.vue'
import { projects } from '@/data/projects.js'

export default {
  name: 'Mapping',
  data() {
    return {
      projects: projects
    }
  },
  components: {
    ProjectCard
  },
  computed: {
    related: function () {
      var related = [
        this.projects[0],
        this.projects[1],
        this.projects[2]
      ]
      return related
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">

.project-hero
  transition: all ease .5s
  border-radius: 0

#led-hero
  background-position: right 5% bottom 0%
  background-size: contain
  background-repeat: no-repeat
  background-image: url('~@/img/led/led-iso.gif')



.descriptor-block
  transition: all ease .5s

.project-tags
  position: relative
  list-style: none
  margin: 0
  padding: 0
  width: 100%
  border-bottom: 1px solid #C4C4C4
  font-size: .75rem
  padding: 20px 0
  margin-top: 12px
  transition: all ease .3s
  &:after
    content: ''
    height: 1px
    background-color: black
    width: 0
    position: absolute
    bottom: -1px
    left: 0
  li
    font-family: 'Space Mono', monospace
    font-size: .75rem
    display: inline-block
    height: auto
    &:not(:first-of-type)
      margin-left: 12px
      padding-left: 12px
      border-left: 1px solid #C4C4C4

.border-bottom
  width: 0%
  height: 2px
  background-color: black
  transition: all ease .5s
  margin: 0 auto

.project-title
  margin-top: 20px
  width: 75%
  font-size: 1.5rem
  font-weight: bold
  line-height: 1.5

.project-description
  margin-top: 20px
  width: 75%
  font-size: 1rem
  line-height: 1.5

.link-display
  font-family: 'Space Mono', monospace
  font-size: .875rem
  margin-top: 24px
  &:after
    content: '→'


.project-logo
  margin-top: 32px
  max-height: 32px
  position: absolute
  bottom: 0

.color-table
  background: linear-gradient(302.58deg, #1A1A2C 6.55%, rgba(19, 5, 48, 0) 99.49%), radial-gradient(66.37% 103.35% at 27.64% 29.43%, #424242 0%, #1E1E1E 100%)
  color: white
  border-radius: 24px
  padding: 32px 40px

.color-table-header
  border-bottom: 1px solid transparentize(white, .95)
  p
    font-size: .75rem
    font-weight: bold
    line-height: 2

.color-table-row
  padding: 32px 0
  border-bottom: 1px solid transparentize(white, .95)
  font-size: .875rem
  line
  &:last-of-type
    border-bottom: none

.led-color-label
  font-weight: bold

.color-swatch
  width: 16px
  height: 16px
  border-radius: 6px
  &#swatch-white
    background-color: white
    box-shadow: 0px 0px 12px #FFFFFF
  &#swatch-purple
    background-color: #A96AFF
    box-shadow: 0px 0px 12px #A96AFF
  &#swatch-blue
    background-color: #3D7BF0
    box-shadow: 0px 0px 12px #3D7BF0
  &#swatch-red
    background-color: #F03D3D
    box-shadow: 0px 0px 12px #F03D3D
  &#swatch-orange
    background-color: #F0883D
    box-shadow: 0px 0px 12px #F0883D
  &#swatch-yellow
    background-color: #F0DE3D
    box-shadow: 0px 0px 12px #F0DE3D
  &#swatch-green
    background-color: #3DF08F
    box-shadow: 0px 0px 12px #3DF08F


</style>

<template>
  <div class="case-study" id="case-led">
    <b-container>
      <b-row class="case-header" id="ml-mapping-hero">
        <b-col cols='3' offset="1">

          <p class="eyebrow">
            Magic Leap
          </p>
          <h3>Spatialized location mapping</h3>

        </b-col>
      </b-row>
      <b-row class="case-intro">
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="4">
          <p class="lead">
            I designed applications and processes for creating spatial reconstructions of a location for AR.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="10" lg="8" offset-md="1" offset-lg="3">
          <img src="@/img/mapping/mapping-overview.jpg"/>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            About
          </p>
        </b-col>
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="0">
          <p>
            For the optimal experience when using a Magic Leap device, the user needs to have a high quality scan of their location. This can be created by the end user themselves, or prepared in advance by a location owner. With Magic Leap, I helped to establish tools and processes for users to create the high quality scans necessary for the AR experiences.
          </p>
          <p>
            In order for an application to position content within the world, the application needs a digital understanding of the space. For example, if a retail store wants to display digital signage within their store, the device needs to have seen the store and know where to position the signage. This digital recreation of the physical world is called a Digital Twin by Magic Leap, as demonstrated below.
          </p>
          <p>
            These digital twins are necessary for not only end users, but location owners, and developers as well. The process for creation of a digital twin was cumbersome and a fragmented expierence for all user roles.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="10" lg="8" offset-md="1" offset-lg="3">
          <img src="@/img/mapping/mapping-digital-twin.jpg"/>
        </b-col>
      </b-row>


      <b-row class="case-row">
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            Discovery
          </p>
        </b-col>
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="0">
          <p>
            To understand the process a user must perform, I worked across multiple organizations internally and externally to Magic Leap where I evaluated the technical and user requirements of creating a high quality Digital Twin.
          </p>
          <p>
            Some artifacts created through this process, were interviews, workshops, user journeys, heuristic analysis documents, all used to drive alignment and create product requirements.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="10" lg="8" offset-md="1" offset-lg="3">
          <img src="@/img/mapping/mapping-journey.png"/>
          <p>Through interviews and a cross-functional workshop, I was able to define workflows of the business and end user.</p>
        </b-col>
      </b-row>
      <b-row class="mt-sm">
        <b-col xs="12" md="5" lg="4" offset-md="1" offset-lg="3">
          <img src="@/img/mapping/mapping-whiteboard.jpg"/>
          <p class="caption">Early sketch of what a mapping visualization tool needed to display.</p>
        </b-col>
        <b-col xs="12" md="5" lg="4">
          <img src="@/img/mapping/mapping-audit.jpg"/>
          <p class="caption">Documenting experience gaps with the existing process.</p>
        </b-col>
      </b-row>

      <b-row class="case-row">
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            Early Designs
          </p>
        </b-col>
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="0">
          <p>
            These artifacts were refined into technical user flows and inital mockups of products needed for users to create high quality scans of their space. These early low fidelity assets were helpful in driving design forward as I worked alongside developers, product managers, and 3D artists to tranform these low fidelity concepts into products for end users and developers.
          </p>
          <p>
            One issue discovered through the discovery process was the difficulty from all sides, developer, end user, and location owner, was the fractured experience of mapping a users space. Each application had created their own mapping process, which was expensive for developers, and a confusing experience for an end user.
          </p>
          <p>
            Mapping and localization are needed by almost every AR application, so it made sense to move this to a platform service that the OS managed. No longer requiring individual applications to create their own mapping service, and letting end users have the same expereince everytime it was used.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="10" lg="8" offset-md="1" offset-lg="3">
          <img src="@/img/mapping/mapping-userflow.jpg"/>
          <p>A UX flow defining how users will perform mapping.</p>
        </b-col>
      </b-row>
      <b-row class="mt-sm">
        <b-col xs="12" md="5" lg="4" offset-md="1" offset-lg="3">
          <img src="@/img/mapping/mapping-minimap-wire.png"/>
          <p class="caption">Greyboxed wireframes for a mapping application.</p>
        </b-col>
        <b-col xs="12" md="5" lg="4">
          <img src="@/img/mapping/mapping-mesh-scan.png"/>
          <p class="caption">Greyboxed wireframes for anchor generation.</p>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            Final designs
          </p>
        </b-col>
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="0">
          <p>
            The mapping service allows for any application to invoke a system managed mapping service that is always consistent for the end user. It displays waypoints to the user asking them to move throughout their space.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" md="5" lg="4" offset-md="1" offset-lg="3">
          <img src="@/img/mapping/mapping-waypoint2.gif"/>
          <p class="caption">A user viewing a waypoint, it guides them to approach.</p>
        </b-col>
        <b-col xs="12" md="5" lg="4">
          <img src="@/img/mapping/mapping-waypoint1.gif"/>
          <p class="caption">Waypoints inform users of how to scan their space. </p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="4" >
          <p>
            Another important discovery was the user misunderstandings of how mapping should be performed. Since every application was doing it differently, there was no central guidance. By moving the mapping service to a central OS service, the mapping service could also inform the user of how and why mapping was important to do.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" md="5" lg="4" offset-md="1" offset-lg="3">
          <img src="@/img/mapping/mapping-tip-1.jpg"/>
          <p class="caption">Users are informed of common issues, such as the need to look in multtiple directions.</p>
        </b-col>
        <b-col xs="12" md="5" lg="4">
          <img src="@/img/mapping/mapping-tip-2.jpg"/>
          <p class="caption">Or other issues, such as the ability to detect certain surfaces.</p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            Review
          </p>
        </b-col>
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="0" >
          <p>
            These improvements, by creating a central mapping service, allowed for developers to focus on their expertise and app logic, letting them create application much quicker. It also refined the end user experience, by providing a consistent process to scan their location and create a high quality map.
          </p>
        </b-col>
      </b-row>
      <b-row class="related-list">
        <b-col cols="12">
          <div class="section-title">
            Other Projects
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="4" v-for="project in related">
          <ProjectCard
            :img="project.img"
            :tags="project.tags"
            :title="project.title"
            :description="project.description"
            :logo="project.logo"
            :route="project.route"
          >
          </ProjectCard>
        </b-col>
      </b-row>

    </b-container>


  </div>
</template>

<script>
import ProjectCard from '@/components/ProjectCard.vue'
import { projects } from '@/data/projects.js'

export default {
  name: 'Mapping',
  data() {
    return {
      projects: projects
    }
  },
  components: {
    ProjectCard
  },
  computed: {
    related: function () {
      var related = [
        this.projects[4],
        this.projects[5],
        this.projects[0]
      ]
      return related
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
#ml-mapping-hero
  background-position: right 5% bottom 0%
  background-size: cover
  background-repeat: no-repeat
  //background-image: url('~@/img/mapping/mapping-banner.png')





</style>

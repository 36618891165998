<template>
  <div class="case-study" id="case-led">
    <b-container>
      <b-row class="case-header" id="ml-guide-hero">
        <b-col cols='3' offset="1">

          <p class="eyebrow">
            Magic Leap
          </p>
          <h3>Defining AR design and interaction guidelines</h3>

        </b-col>
      </b-row>
      <b-row class="case-intro">
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="4">
          <p class="lead">
            Design and developer documents describing best practices for spatial computing and interacting with a Magic Leap device.
          </p>
        </b-col>
      </b-row>


      <b-row class="case-row">
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            About
          </p>
        </b-col>
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="0" class="bb-case">
          <p>
            Spatial computing is an emerging technology with many unknowns and little standardization. This can easily lead to a fractured user experience when scaled across a new operating system.
          </p>
          <p>
            I worked with a small team of designers at argodesign and inside of Magic Leap to craft standards which guided designers and developers into a cohesive experience across the platform.
          </p>
          <a href="https://developer.magicleap.com/en-us/learn/guides/design-spatial-computing">You can read the guides here.</a>

        </b-col>
      </b-row>

      <b-row class="case-row">
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            Discovery
          </p>
        </b-col>
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="0" class="bb-case">
          <p>
            At the start of the project, I playtested early prototypes and release candidates of the Magic Leap One and its applications.
          </p>
          <p>
            Through this discovery I found a wide array of design patterns across the OS and applications which were not aligned with each other. This would often leave a user frustrated with the system and unsure of how to perform simple tasks.
          </p>

        </b-col>
      </b-row>

      <b-row class="case-row">
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            Standardization
          </p>
        </b-col>
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="0" class="bb-case">
          <p>
            The team and I then consolidated feedback and worked to implement the most standardized and widely used patterns. This was done through collaboration with each application’s developers, designers, and product managers.
          </p>
          <p>
            A few artifacts created in this stage were detailed flow diagrams and low fidelity prototypes demonstrating how inputs should be used for navigation and interactivity.
          </p>

        </b-col>
      </b-row>

      <b-row class="case-row">
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            Publish &amp; Share
          </p>
        </b-col>
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="0" >
          <p>
            As a platform, we knew to maintain consistency with scale we would need to publish guidelines and make them available to 3rd party developers.
          </p>
          <p>
            Most of these developers creating content at this stage, did not have designers and were small tech focused teams. This informed our approach and led to guidelines which discussed broad user focused design principles, as well as narrow, specific interaction mechanics. Spanning from ‘intro to design’ all the way to practical implementation discussions.
          </p>
          <p>
            The design guidelines were robust, at initial launch consisting of over 100 documents. To help guide users we organized the documentation under 4 main categories:
          </p>
          <ol>
            <li>
              <h5>Explaining the Medium </h5>
              <p>Spatial computing is brand new to most people, it was important to describe it and define a few considerations.</p>
            </li>
            <li>
              <h5>The Interaction Model  </h5>
              <p>Giving definition to basic states such as targeting, applying focus, and performing selection. These discussions also informed developers of which input mechanisms, such as the controller, gestures, and gaze, performed best for each. </p>
            </li>
            <li>
              <h5>Visual Style</h5>
              <p>Describing visual standards and informing developers and designers of unique challenges of designing digital content appearing alongside the physical world.
              </p>
            </li>
            <li>
              <h5>Components</h5>
              <p>Explaining the existing UI patterns which existed and why they should be used.
              </p>
            </li>
          </ol>

        </b-col>
      </b-row>

      <b-row class="case-row">
        <b-col cols="10" offset-md="2" class="video-container">

          <iframe
            src="https://www.youtube.com/embed/0T6Y2EH9th4?controls=0&showinfo=0&modestbranding=1&autoplay=1&loop=1&rel=0&mute=1&playlist=0T6Y2EH9th4"
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="video"></iframe>
        </b-col>
      </b-row>
      <b-row class="mt-sm">
        <b-col cols="12" md="10" offset-md="2">
          <img src="@/img/ml-guides/mlguides-hero.png"/>
        </b-col>
      </b-row>
      <b-row class="mt-sm">
        <b-col cols="12" md="5" offset-md="2" >
          <img src="@/img/ml-guides/mlguides-movefocus.gif"/>
        </b-col>
        <b-col cols="12" md="5">
          <img src="@/img/ml-guides/mlguides-select.gif"/>
        </b-col>
      </b-row>
      <b-row class="mt-sm">
        <b-col cols="12" md="5" offset-md="2">
          <img src="@/img/ml-guides/mlguides-distance.gif"/>
        </b-col>
        <b-col cols="12" md="5" >
          <img src="@/img/ml-guides/mlguides-back.gif"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="10" lg="6" offset-md="1" offset-lg="4">
          <p>
            The guidelines were published publicly for all external developers alongside the release of the Magic Leap One.
          </p>
          <p>
            They were also enforced through the publishing process, with compliance standards referencing the guides and pushing consistent interaction behaviors across the platform.
          </p>
        </b-col>
      </b-row>
      <b-row class="related-list">
        <b-col cols="12">
          <div class="section-title">
            Other Projects
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="4" v-for="project in related">
          <ProjectCard
            :img="project.img"
            :tags="project.tags"
            :title="project.title"
            :description="project.description"
            :logo="project.logo"
            :route="project.route"
          >
          </ProjectCard>
        </b-col>
      </b-row>

    </b-container>


  </div>
</template>

<script>
import ProjectCard from '@/components/ProjectCard.vue'
import { projects } from '@/data/projects.js'

export default {
  name: 'Guides',
  data() {
    return {
      projects: projects
    }
  },
  components: {
    ProjectCard
  },
  computed: {
    related: function () {
      var related = [
        this.projects[5],
        this.projects[0],
        this.projects[1]
      ]
      return related
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
#ml-guide-hero
  background-position: right 5% bottom 0%
  background-size: cover
  background-repeat: no-repeat
  background-image: url('~@/img/ml-guides/mlguides-banner.jpg')





</style>

<template>
  <div class="case-study" id="case-led">
    <b-container>
      <b-row class="case-header" id="scenes-hero">
        <b-col cols='3' offset="1">

          <p class="eyebrow">
            Scenes
          </p>
          <h3>Enabling rapid prototyping for AR</h3>

        </b-col>
      </b-row>
      <b-row class="case-intro">
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="4">
          <p class="lead">
            I designed and developed a prototyping tool enabling designers to move designs from a desktop computer to an interactive spatialized prototype for a Magic Leap device.
          </p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            Problem
          </p>
        </b-col>
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="0" >
          <p>
            Displaying designs directly on a Magic Leap device can be a challenge. Moving a design from the computer, where the designs were made, onto a device could take hours or longer.
          </p>
          <p>
            It required a designer to know Unity or C++, and the workflow for building and deploying an application. Otherwise it would take a developer time to build a prototype for the designer.
          </p>
          <p>
            This process is technical and requires development time. It inhibits the process of quick iteration and viewing designs in actual 3D environments.
          </p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12">
          <img src="@/img/scenes/scenes-existing.png"/>
          <p class="caption">The steps required to put a design on device.</p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            Solution
          </p>
        </b-col>
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="0" >
          <p>
            To solve this issue I designed and built a web application where a designer could upload their designs to a project and sequence them to create a simple prototype. This followed the workflows designers where use to with other platforms, such as inVision.
          </p>
          <p>
            A designer could simply upload designs into the web tool and see them immediately within their space on the Magic Leap One in their Landscape environment.
          </p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12">
          <img src="@/img/scenes/scenes-updated.png"/>
          <p class="caption">The steps used when using scenes.</p>
        </b-col>
      </b-row>
      <b-row class="case-row mt-sm">
        <b-col cols="12">
          <img src="@/img/scenes/scenes-overview.png"/>
          <p class="caption">Users have a list of scenes to display, and a detail page for each scene.</p>
        </b-col>
      </b-row>
      <b-row class="case-row mt-sm">
        <b-col cols="12">
          <img src="@/img/scenes/scenes-img-support.jpg"/>
          <p class="caption">The tool allows for flat images as well as 3D assets to be uploaded into the view.</p>
        </b-col>
      </b-row>
      <b-row class="case-row mt-sm">
        <b-col cols="12">
          <img src="@/img/scenes/scenes-hotspot.jpg"/>
          <p class="caption">A designer could then define where users could perform actions, and what they would do.</p>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            Tools
          </p>
        </b-col>
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="0" >
          <p>
            Javascript was used through the entire project. A user would interface with a web app built with Vue.js, this would authenticate a user and allow a user to create a project and upload scenes. A user could then apply hotspots to the scene, selecting an interaction, and the outcome.
          </p>
          <p>
            This data was uploaded to a Firebase backend and acted as the central store and helped handle user authentication and management.
          </p>
          <p>
            A designer would then put on their Magic Leap One and open the app. They would select their project and see their designs directly in their space. They could then click through them easily viewing an app flow or interaction sequence without needing to develop a full application first.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="10" lg="8" offset-md="1" offset-lg="3">
          <img src="@/img/scenes/scenes-tools.png"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="10" lg="8" offset-md="1" offset-lg="3">
          <img src="@/img/scenes/scenes-tools-rep.jpg"/>
        </b-col>
      </b-row>
      <b-row class="case-row">
        <b-col cols="12" md="8" lg="3" offset-md="2" offset-lg="1">
          <p class="case-section-title">
            Using the tool
          </p>
        </b-col>
        <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="0" >
          <p>
            Shown here, a designer can take assets exported from their existing workflow, whether those are 2D or 3D and add them to the tool.
          </p>
          <p>
            They can specify the user action and the response.
          </p>
          <p>
            Once completed, they can open the Scenes app on device and see their app in their environment.
          </p>
        </b-col>

      </b-row>
      <b-row>
        <b-col xs="12" md="5" lg="4" offset-md="1" offset-lg="3">
          <img src="@/img/scenes/scenes-inspect.jpeg"/>
          <p class="caption">A user has uploaded an asset to Scenes.</p>
        </b-col>
        <b-col xs="12" md="5" lg="4">
          <img src="@/img/scenes/scenes-output.jpeg"/>
          <p class="caption">The asset is immediately available on the Magic Leap device.</p>
        </b-col>
      </b-row>

      <b-row class="related-list">
        <b-col cols="12">
          <div class="section-title">
            Other Projects
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="4" v-for="project in related">
          <ProjectCard
            :img="project.img"
            :tags="project.tags"
            :title="project.title"
            :description="project.description"
            :logo="project.logo"
            :route="project.route"
          >
          </ProjectCard>
        </b-col>
      </b-row>

    </b-container>


  </div>
</template>

<script>
import ProjectCard from '@/components/ProjectCard.vue'
import { projects } from '@/data/projects.js'

export default {
  name: 'Scenes',
  data() {
    return {
      projects: projects
    }
  },
  components: {
    ProjectCard
  },
  computed: {
    related: function () {
      var related = [
        this.projects[2],
        this.projects[3],
        this.projects[4]
      ]
      return related
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
#scenes-hero
  background-position: right 5% bottom 0%
  background-size: contain
  background-repeat: no-repeat
  background-image: url('~@/img/scenes/scenes-banner.jpg')





</style>
